import React, { useEffect } from "react"
import { navigate } from "gatsby"
import ContentWithBgLayout from "../components/contentWithBgLayout"
import { Footer } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import ResetPassword from "../components/resetPassword"
import Seo from "../components/seo"
import { useQueryParam, StringParam } from "use-query-params"

const NewPasswordPage = () => {
    const [token] = useQueryParam("t", StringParam)

    useEffect(() => {
        if (!token) navigate("/")
        else localStorage.setItem("token", token)
    }, [token])

    return (
        <Layout>
            <Seo title="New Password" />
            <NavBar whiteNav bookNow={false} />
            <ContentWithBgLayout>
                <ResetPassword />
            </ContentWithBgLayout>
            <Footer />
        </Layout>
    )
}

export default NewPasswordPage
