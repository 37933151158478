import { Link } from "gatsby"
import React from "react"

const ResetedPassword = () => {
    return (
        <div className="col paddingy-forgot-special d-flex flex-column align-items-center">
            <div className="col d-flex flex-column align-items-center text-center">
                <h2>Password reset</h2>
                <p className="mt-1 mt-md-2 mb-0">
                    Your password has been successfully reset.
                    <br />
                    Click below to sign in.
                </p>
            </div>
            <div className="forgot-form-container mt-3 mt-md-4">
                <div className="row gy-3">
                    <div className="col-12 d-flex justify-content-center mt-3">
                        <Link to="/booking" className="button fill light-background w-continue-reset text-center">
                            continue
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetedPassword
