import React, { useState } from "react"
import ResetedPassword from "./resetedPassword"
import SetNewPassword from "./setNewPassword"
import fetchQuery from "../utils/fetchQuery"

const ResetPassword = () => {
    const [changed, setChanged] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [errorMsg, setErrorMsg] = useState(null)

    const submit = async () => {
        setErrorMsg(null)
        await fetchQuery({
            method: "POST",
            endPoint: "auth/setpassword",
            body: {
                password
            },
            successCallback: response => {
                if (response === 2000) {
                    setChanged(true)
                }
                setIsLoading(false)
            },
            failCallback: () => {
                setIsLoading(false)
                setErrorMsg("Something went wrong. Please reload the page.")
            }
        })
    }

    const onReset = () => {
        const newErrors = {}

        setIsLoading(true)

        if (!password.length) {
            newErrors.password = "Required"
        } else {
            if (password.length < 12) {
                newErrors.password = "Length must be at least 12 characters"
            }
        }
        if (!confirmPassword.length) {
            newErrors.confirmPassword = "Required"
        } else {
            if (password !== confirmPassword) {
                newErrors.confirmPassword = "Password doesn't match"
            }
        }

        if (!Object.keys(newErrors).length) {
            submit()
            setErrors({})
        } else {
            setErrors(newErrors)
            setIsLoading(false)
        }
    }

    return !changed ? (
        <SetNewPassword
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            onReset={onReset}
            isLoading={isLoading}
            errors={errors}
            errorMsg={errorMsg}
        />
    ) : (
        <ResetedPassword />
    )
}

export default ResetPassword
