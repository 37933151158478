import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import BackArrow from "../images/back-arrow.svg"

const SetNewPassword = ({ password, setPassword, confirmPassword, setConfirmPassword, onReset, isLoading, errors, errorMsg }) => {
    return (
        <div className="row">
            <div className="col paddingy-special d-flex flex-column align-items-center">
                <div className="col d-flex flex-column align-items-center text-center">
                    <h2>Set new password</h2>
                    <p className="mt-1 mt-md-2 mb-0">
                        Your new password must be different to
                        <br />
                        previously used passwords.
                    </p>
                </div>
                <div className="login-container mt-3 mt-md-4">
                    <div className="row gy-3">
                        <div className="col-12">
                            <div className="form-floating">
                                <input
                                    type="password"
                                    className={`form-control ${errors.password ? "error" : ""} ${
                                        password ? "color-purple-border" : ""
                                    }`}
                                    id="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <label htmlFor="password" className={`${password ? "color-purple" : ""}`}>
                                    Password
                                </label>
                                {errors.password && <span className="form-error-message">{errors.password}</span>}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-floating">
                                <input
                                    type="password"
                                    className={`form-control ${errors.confirmPassword ? "error" : ""} ${
                                        confirmPassword ? "color-purple-border" : ""
                                    }`}
                                    id="password2"
                                    placeholder="Password"
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                <label htmlFor="password2" className={`${confirmPassword ? "color-purple" : ""}`}>
                                    Confirm password
                                </label>
                                {errors.confirmPassword && <span className="form-error-message">{errors.confirmPassword}</span>}
                                {errorMsg && <span className="form-error-message">{errorMsg}</span>}
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center mt-3 mt-md-4">
                            <button
                                disabled={isLoading}
                                onClick={onReset}
                                type="button"
                                className="button fill light-background w-reset-check-button"
                            >
                                <span>reset password</span>
                            </button>
                        </div>
                        <div className="col-12 d-flex justify-content-center mt-3 mt-md-4">
                            <Link to="/signin" className="text-decoration-none d-flex justify-content-center align-items-center">
                                <img className="back-sign-in" src={BackArrow} alt="Arrow Back" />
                                Back to sign in
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SetNewPassword.propTypes = {
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
    setPassword: PropTypes.func.isRequired,
    setConfirmPassword: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired
}

export default SetNewPassword
